import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/users',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: parseUrl('users', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await axiosInstance({
      url: '/users',
      data: data,
      method: 'POST'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, queryParams) {
  try {
    const response = await axiosInstance({
      url: `/users/${queryParams.id}`,
      data: queryParams.data,
      method: 'PUT'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function deleteUser(ctx, queryParams) {
  try {
    const response = await axiosInstance({
      url: parseUrl('users', queryParams),
      data: queryParams,
      method: 'DELETE'
    })
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}