import {
  Loading,
  QSpinnerDots
} from 'quasar'

export default ({ router, store, Vue  }) => {
  router.beforeEach((to, from, next) => {
    Loading.show({
      spinner:QSpinnerDots
    })
    if (to.meta.auth === false) {
      next()
    } else {
      if (store.state.auth.isAuthenticated) {
        next()
      } else if ((!store.state.auth.isAuthenticated && to.path == '/public/media') || (!store.state.auth.isAuthenticated && to.path == '/media')){
        next('/public/media')
      } else {
        next('/auth/login')
      }
    }
  })
  router.afterEach((to, from) => {
    Loading.hide()
  })
}
