/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import '@quasar/extras/eva-icons/eva-icons.css'




// We load Quasar stylesheet file
import 'quasar/dist/quasar.sass'




import 'src/css/app.scss'


import Vue from 'vue'
import createApp from './app.js'


import 'app/src-pwa/register-service-worker.js'




import qboot_Bootaxios from 'boot/axios'

import qboot_Bootauth from 'boot/auth'

import qboot_Bootcookies from 'boot/cookies'

import qboot_Bootnotify from 'boot/notify'

import qboot_Bootrouter from 'boot/router'

import qboot_Bootfilters from 'boot/filters'

import qboot_Bootmsnotify from 'boot/ms-notify'



import { addPreFetchHooks } from './client-prefetch.js'





Vue.config.devtools = true
Vue.config.productionTip = false





// Needed only for iOS PWAs
if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && window.navigator.standalone) {
  import(/* webpackChunkName: "fastclick"  */ '@quasar/fastclick')
}


const publicPath = `/`


async function start () {
  const { app, store, router } = await createApp()

  

  
  let hasRedirected = false
  const redirect = url => {
    hasRedirected = true
    const normalized = Object(url) === url
      ? router.resolve(url).route.fullPath
      : url

    window.location.href = normalized
  }

  const urlPath = window.location.href.replace(window.location.origin, '')
  const bootFiles = [qboot_Bootaxios,qboot_Bootauth,qboot_Bootcookies,qboot_Bootnotify,qboot_Bootrouter,qboot_Bootfilters,qboot_Bootmsnotify]

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null,
        redirect,
        urlPath,
        publicPath
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }

      console.error('[Quasar] boot error:', err)
      return
    }
  }

  if (hasRedirected === true) {
    return
  }
  

  

    
    addPreFetchHooks(router, store)
    

    

    
      new Vue(app)
    

    

    

  

}

start()
