
const routes = [
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      { path: "", name: 'dashboard', component: () => import("pages/Index.vue") },
      {
        path: "users",
        name: "users",
        component: () => import("pages/Users.vue")
      },
      {
        path: "sellers",
        name: "sellers",
        component: () => import("pages/Sellers.vue")
      },
      {
        path: "branches",
        name: "branches",
        component: () => import("pages/Branches.vue")
      },
      {
        path: "surveys",
        name: "surveys",
        component: () => import("pages/Surveys.vue")
      },
      {
        path: "detractors/:id?",
        name: "detractors",
        component: () => import("pages/Detractors.vue")
      },
      {
        path: "templates-surveys",
        name: "templates-surveys",
        component: () => import("pages/TemplatesSurveys.vue")
      },
      {
        path: "dealers",
        name: "dealers",
        component: () => import("pages/Dealers.vue")
      },
      {
        path: "comments",
        name: "comments",
        component: () => import("pages/Comments.vue")
      },
      {
        path: "media", 
        name: "media", 
        component: () => import("src/pages/media.vue"),
      }
    ]
  },
  {
    path: "/auth",
    component: () => import("layouts/MainLogin.vue"),
    children: [
      {
        path: "login", name: "login", component: () => import("pages/auth/login.vue"),
        meta: {
          auth: false
        },
      },
      {
        path: "pin-verification",
        name: "pin-verification",
        component: () => import("pages/auth/PinVerification.vue"),
        meta: {
          auth: false
        },
      },
      {
        path: "recover-password",
        name: "recover-password",
        component: () => import("pages/auth/RecoverPassword.vue"),
        meta: {
          auth: false
        },
      },
      {
        path: "confirm-password/:token",
        name: "confirm-password",
        component: () => import("pages/auth/ConfirmPassword.vue"),
        meta: {
          auth: false
        },
      },
      {
        path: "verify-account/:token",
        name: "verify-account",
        component: () => import("pages/auth/ConfirmAccount.vue"),
        meta: {
          auth: false
        },
      }
    ]
  },
  {
    path: "/public",
    component: () => import("layouts/PublicLayout.vue"),
    children: [
      {
        path: "media", name: "media", component: () => import("src/pages/media.vue"),
        meta: {
          auth: false
        },
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "*",
    component: () => import("pages/Error404.vue")
  }
];

export default routes
