import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      // url: '/trackings/'+queryParams.id,
      url: '/trackings',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await axiosInstance({
      url: '/trackings/',
      data: data,
      method: 'POST'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function udpate(ctx, data) {
  try {
    const response = await axiosInstance({
      url: `/trackings/${data.id}`,
      data: data.model,
      method: 'PUT'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function download(ctx, data) {
  try {
    const response = await axiosInstance({
      url: '/surveys/template',
      method: 'GET'
    })
    return response
  } catch (e) {
    return e
  }
}



