<template>
  <!-- eslint-disable vue/no-v-html -->
  <transition name="ms-notify-fade">
    <div
      v-show="visible"
      :class="[customClass, type]"
      :style="positionStyle"
      class="ms-notify"
      @mouseenter="clearTimer()"
      @mouseleave="startTimer()"
      @click="click"
    >
      <q-btn
        icon="eva-close-outline"
        flat
        dense
        class="btn-close"
        @click="close"
      />
      <div class="icon">
        <div
          class="icon-wrapper"
          :class="type"
        >
          <q-icon
            :name="iconTypeClass"
            size="sm"
          />
        </div>
      </div>
      <div class="message">
        <h5>{{ title }}</h5>
        <p v-if="!dangerouslyUseHTMLString">
          {{ message }}
        </p>
        <p
          v-else
          v-html="message"
        />
      </div>
    </div>
  </transition>
</template>

<script>
/**
 *
 * MsNotify Component
 *
 * @author Emmanuel Hernández
 * @copyright 2019, Marciano Studio S.C.
 */

export default {
  name: 'MsNotify',
  data() {
    return {
      title: '',
      message: '',
      onClose: null,
      onClick: null,
      visible: false,
      closed: false,
      timer: null,
      duration: 8500,
      verticalOffset: 0,
      customClass: '',
      type: 'info',
      showClose: true,
      dangerouslyUseHTMLString: false
    }
  },
  computed: {
    iconTypeClass() {
      switch (this.type) {
        case 'success':
          return 'eva-checkmark-circle-outline'
        case 'error':
          return 'eva-close-circle-outline'
        case 'warning':
          return 'eva-alert-triangle-outline'
        case 'info':
          return 'eva-info-outline'
        default:
          return 'eva-info-outline'
      }
    },
    positionStyle() {
      return {
        bottom: `${this.verticalOffset}px`
      }
    }
  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.visible = false
        this.$el.addEventListener('transitionend', this.destroyElement)
      }
    }
  },
  mounted() {
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close()
        }
      }, this.duration)
    }
    document.addEventListener('keydown', this.keydown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown)
  },
  methods: {
    destroyElement() {
      this.$el.removeEventListener('transitionend', this.destroyElement)
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    },
    close() {
      this.closed = true
      if (typeof this.onClose === 'function') {
        this.onClose()
      }
    },
    click() {
      if (typeof this.onClick === 'function') {
        this.onClick()
      }
    },
    clearTimer() {
      clearTimeout(this.timer)
    },
    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close()
          }
        }, this.duration)
      }
    },
    keydown(e) {
      if (e.keyCode === 46 || e.keyCode === 8) {
        this.clearTimer()
      } else if (e.keyCode === 27) {
        if (!this.closed) {
          this.close()
        }
      } else {
        this.startTimer()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ms-notify {
  width: 400px;
  min-height: 100px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 8000;
  display: flex;
  flex-flow: row nowrap;
  transition: bottom 300ms linear;
  background-color: #fff;
  box-shadow: 0 10px 30px #47546D4D;
  border-radius: 5px;
  padding: 25px;

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .icon {
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding-right: 15px;

    .icon-wrapper {
      border-radius: 50%;
      padding: 10px;
      background-color: rgba(#27509B, 0.2);
      color: #27509B;

      &.success {
        background-color: rgba(#4DC64E, 0.2);
        color: #4DC64E
      }

      &.error {
        background-color: rgba(#EF3535, 0.2);
        color: #EF3535;
      }

      &.warning {
        background-color: rgba(#F2BE2F, 0.2);
        color: #F2BE2F;
      }

      &.info {
        background-color: rgba(#27509B, 0.2);
        color: #27509B;
      }
    }
  }

  .message {
    height: 100%;
    width: 100%;
    position: relative;

    h5 {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: #47546D;
      margin: 0;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #7D8698;
      margin: 0;
    }
  }
}

.ms-notify-fade-enter-active {
  transition: all 250ms ease-in-out;
}

.ms-notify-fade-leave-active {
  transition: all 250ms ease-in-out;
}

.ms-notify-fade-enter {
  transform: translateX(100%);
}

.ms-notify-fade-leave-to {
  opacity: 0;
}
</style>
