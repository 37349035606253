export function items(state, data) {
  data.forEach(element => {
    element.email = element.email ? element.email.toString().trim() : null
    if (element.productOrService) {
      switch (element.productOrService.trim().toLowerCase()) {
        case 'p':
        case 'producto':
        case 'produto':
        case 'prod':
        case 'product':
          element.productOrService = 'PRODUCT'
          break
        case 's':
        case 'servicio':
        case 'servisio':
        case 'serv':
        case 'service':
          element.productOrService = 'SERVICE'
          break
        case 'ambos':
        case 'amvos':
        case 'anbos':
        case 'both':
          element.productOrService = 'PRODUCT_AND_SERVICE'
          break
        default:
          element.productOrService = null
          break
      }
    }
  })
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function item(state, data) {
  state.item = data
}

export function remove(state, position) {
  state.items.splice(position, 1)
}

export function saveError(state, position) {
  let itemsClone = { ...state.items }
  itemsClone[position].status = 'ERROR'
  state.items = itemsClone
}

export function saveSuccess(state, position) {
  let itemsClone = { ...state.items }
  itemsClone[position].status = 'SUCCESS'
  state.items = itemsClone
}
