export function items(state) {
  return state.items
}

export function item(state) {
  return state.item
}

export function nps(state) {
  let nps = 0
  if(state.items && state.items.type){
    let total = state.items.type.detractor + state.items.type.passive + state.items.type.promoter
    nps = total > 0 ? Math.round((( state.items.type.promoter / total) - (state.items.type.detractor/total)) * 100) : 0
  }

  return nps
}

export function pagination(state) {
  return state.pagination
}
