import { Cookies } from 'quasar'
import { axiosInstance } from './axios'

export default ({ store, ssrContext }) => {
  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies
  if (cookies.has('auth_token')) {
    store.commit('auth/isAuthenticated', true)
    axiosInstance.defaults.headers.common['Authorization'] =
      'Bearer ' + cookies.get('auth_token')
  }
}
