export function isAuthenticated(state) {
  return state.isAuthenticated
}

export function me(state) {
  return state.me
}

export function branches(state) {
  return state.branches
}