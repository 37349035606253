export default function () {
  return {
    items: [
      { value: 'administrator', label: 'Administrador' },
      { value: 'general_manager', label: 'Director' },
      { value: 'manager', label: 'Gerente' },
      { value: 'seller', label: 'Vendedor' }
    ]
  }
}
