import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      // url: '/trackings/'+queryParams.id,
      url: '/follow-ups',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await axiosInstance({
      url: '/follow-ups',
      data: data,
      method: 'POST'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function clear(ctx) {
  try {
    ctx.commit('items', [])
    return response
  } catch (e) {
    return e
  }
}

