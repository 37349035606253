import { axiosInstance } from 'boot/axios.js'
import getCookies from 'boot/cookies.js'

export async function login(ctx, data) {
  try {
    const response = await axiosInstance({
      url: 'auth/token',
      method: 'post',
      data: data
    })

    const cookies = getCookies()

    const options = {
      secure: process.env.PROD,
      expires: parseInt(response.data.tokenLife) / 60 / 1000
    }

    cookies.set('auth_token', response.data.token, options)
    cookies.set('auth_token_life', response.data.tokenLife, options)
    cookies.set('auth_token_type', response.data.type, options)

    options.expires = parseInt(response.data.refreshTokenLife) / 60 / 1000
    cookies.set('refresh_token', response.data.refreshToken, options)
    cookies.set('refresh_token_life', response.data.refreshTokenLife, options)

    ctx.commit('isAuthenticated', true)

    axiosInstance.defaults.headers.common['Authorization'] =
      'Bearer ' + response.data.token

    return response
  } catch (e) {
    return e
  }
}

export async function logout(ctx) {
  try {
    const response = await axiosInstance({
      url: 'auth/revoke',
      method: 'post'
    })
    const cookies = getCookies()

    cookies.remove('auth_token')
    cookies.remove('auth_token_life')
    cookies.remove('auth_token_type')
    cookies.remove('refresh_token')
    cookies.remove('refresh_token_life')

    delete axiosInstance.defaults.headers.common['Authorization']

    ctx.commit('me', {})

    return response
  } catch (e) {
    return e
  }
}

export async function me(ctx){
  try {
    const response = await axiosInstance({
      url: 'auth/me',
      method: 'get'
    });
    ctx.commit('me', response.data)
    return response
  } catch (e) {
    return e
  }
}

/**
 * Gets the branches that the user can view
 * @param {*} ctx 
 * @returns 
 */
export async function branches(ctx){
  try {
    const response = await axiosInstance({
      url: 'branches?limit=1000',
      method: 'get'
    });
    ctx.commit('branches', response.data.items)
    return response
  } catch (e) {
    return e
  }
}

export async function recover(ctx, data) {
  try {
    const response = await axiosInstance({
      url: 'auth/recover-password',
      method: 'post',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}

export async function reset(ctx, data) {
  try {
    const response = await axiosInstance({
      url: 'auth/reset-password',
      method: 'post',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}

export async function confirmAccount(ctx, data) {
  try {
    const response = await axiosInstance({
      url: 'auth/verify-account',
      method: 'post',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}
