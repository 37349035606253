export function items(state, data) {
  state.items = data
}
export function itemsMspns(state, data) {
  state.itemsMspns = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function item(state, data) {
  state.item = data
}
