import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'

export default function(val, includeTime = false) {
  if (val){
    let date = new Date(val.trim().replace(' ', 'T'))
    return formatRelative(date, new Date(), { locale: es })
  }
    else{
      return null
    }
}
