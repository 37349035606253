export default function(v) {
  if (v) {
    let r = v.replace(/\D/g, '')
  
    let twoDigitPhones = ['55', '81', '33']
  
    if (twoDigitPhones.indexOf(r.slice(0, 2)) > -1) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
    } else {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{2})(\d{2}).*/, '($1) $2-$3-$4')
    }
  
    return r
    
  } else {
    return v
  }
}
