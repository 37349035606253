export function isAuthenticated(state, authenticated) {
  state.isAuthenticated = authenticated
}

export function me(state, payload) {
  state.me = payload
}

export function branches(state, payload) {
  state.branches = payload
}