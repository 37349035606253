import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/dashboard',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function top(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/dashboard/top',
      params: queryParams,
      method: 'GET'
    })
    return response
  } catch (e) {
    return e
  }
}
