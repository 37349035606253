import MsNotify from 'components/_shared/ms-notify'
import Vue from 'vue'

const MsNotifyConstructor = Vue.extend(MsNotify)

let instance
let instances = []
let seed = 1
let zIndex = 8000

const MsNotifyPlugin = options => {
  const userOnClose = options.onClose
  const id = 'notification_' + seed++

  options.onClose = () => {
    MsNotifyPlugin.close(id, userOnClose)
  }

  instance = new MsNotifyConstructor({
    data: options
  })

  instance.id = id
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.vm.visible = true
  instance.dom = instance.vm.$el
  instance.dom.style.zIndex = zIndex++

  let verticalOffset = options.offset || 0
  instances.forEach(item => {
    verticalOffset += item.$el.offsetHeight + 16
  })
  verticalOffset += 16
  instance.verticalOffset = verticalOffset
  instances.push(instance)
  return instance.vm
}
;['success', 'warning', 'info', 'error'].forEach(type => {
  MsNotifyPlugin[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return MsNotifyPlugin(options)
  }
})

MsNotifyPlugin.close = (id, userOnClose) => {
  let index = -1
  const len = instances.length
  const instance = instances.filter((instance, i) => {
    if (instance.id === id) {
      index = i
      return true
    }
    return false
  })[0]
  if (!instance) return

  if (typeof userOnClose === 'function') {
    userOnClose(instance)
  }
  instances.splice(index, 1)

  if (len <= 1) return
  const position = instance.position
  const removedHeight = instance.dom.offsetHeight
  for (let i = index; i < len - 1; i++) {
    if (instances[i].position === position) {
      instances[i].dom.style.bottom =
        parseInt(instances[i].dom.style.bottom, 10) - removedHeight - 16 + 'px'
    }
  }
}

export default MsNotifyPlugin
