import axios from 'axios'
import { Notify } from 'quasar'
import getError from 'src/utils/errors-catalog.js'

const axiosInstance = axios.create({
  baseURL: process.env.API
})

const CancelToken = axios.CancelToken

export default ({ Vue, store, router }) => {
  Vue.prototype.$axios = axiosInstance
  Vue.prototype.$CancelToken = CancelToken

  axiosInstance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status == 401) {
        router.push('/auth/login')
      }
      Notify.error(getError(error.response.status, error.response.data.code))
      return Promise.reject(error)
    }
  )
}

export { axiosInstance }
