const errors = {
  500: {
    DEFAULT:
      'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.'
  },
  401: {
    DEFAULT: 'Debe estar autenticado para poder visualizar a este recurso',
    AUTH_TOKEN_COMPROMISED:
      'Su sesión ha sido usada en otro dispositivo y ha sido desactivada. Vuelva a iniciar sesión para ingresar nuevamente a la plataforma.',
    AUTH_INVALID_CREDENTIALS: 'Su usuario o contraseña son incorrectos'
  },
  422: {
    100: 'Tiene errores de validación en el formulario.'
  },
  422: {
    ERROR_SURVEYS_PENDINGS: 'Este usuario tiene encuestas pendientes.'
  },
  403: {
    NOT_ALLOWED: 'No tienes permiso para está acción.'
  }

}

const getError = (status, code = 'DEFAULT') => {
  const _status = errors[status] ? status : 500
  const _code = errors[_status][code] ? code : 'DEFAULT'
  return errors[_status][_code]
}

export default getError
