import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import users from './users'
import customers from './customers'
import menu from './menu'
import typeUsers from './typeUsers'
import branches from './branches'
import survey from './survey'
import surveyTemplates from './surveyTemplates'
import questionTemplates from './questionTemplates'
import dealers from './dealers'
import states from './states'
import municipalities from './municipalities'
import regions from './regions'
import trackings from './trackings'
import followUps from './follow-ups'
import media from './media'
import dashboard from './dashboard'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      auth,
      users,
      customers,
      dealers,
      menu,
      typeUsers,
      branches,
      survey,
      surveyTemplates,
      questionTemplates,
      states,
      municipalities,
      regions,
      trackings,
      followUps,
      dashboard,
      media
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEBUGGING
  })

  return Store
}
