export default function () {
  return {
    currentMenu: null,
    superadmin: [
      {
        title: "Dashboard",
        icon: "img:icons/dashboard-white.svg",
        link: "/"
      },
      {
        title: "Templates",
        icon: "img:icons/clipboard.svg",
        link: "/templates-surveys"
      },
      {
        title: "Encuestas",
        icon: "img:icons/clipboard.svg",
        link: "/surveys"
      },
      // {
      //   title: "Detractores",
      //   icon: "img:icons/detractor.svg",
      //   link: "/detractors"
      // },
      {
        title: "Comentarios",
        icon: "eva-email-outline",
        link: "/comments"
      },
      {
        title: "Usuarios",
        icon: "img:icons/user.svg",
        link: "/users",
        type: 'config'
      },
      {
        title: "Sucursales",
        icon: "img:icons/map-pin.svg",
        link: "/branches",
        type: 'config'
      },
      {
        title: "Distribuidores",
        icon: "img:icons/shopping-bag.svg",
        link: "/dealers",
        type: 'config'
      }
    ],
    administrator: [
      {
        title: "Dashboard",
        icon: "img:icons/dashboard-white.svg",
        link: "/"
      },
      {
        title: "Encuestas",
        icon: "img:icons/clipboard.svg",
        link: "/surveys"
      },
      // {
      //   title: "Detractores",
      //   icon: "img:icons/detractor.svg",
      //   link: "/detractors"
      // },
      {
        title: "Comentarios",
        icon: "eva-email-outline",
        link: "/comments"
      },
      {
        title: "Usuarios",
        icon: "img:icons/user.svg",
        link: "/users",
        type: 'config'
      },
      {
        title: "Sucursales",
        icon: "img:icons/map-pin.svg",
        link: "/branches",
        type: 'config'
      },
      {
        title: "Distribuidores",
        icon: "img:icons/shopping-bag.svg",
        link: "/dealers",
        type: 'config'
      }
    ],
    seller: [
      {
        title: "Dashboard",
        icon: "img:icons/dashboard-white.svg",
        link: "/"
      },
      {
        title: "Encuestas",
        icon: "img:icons/clipboard.svg",
        link: "/surveys"
      },
      // {
      //   title: "Detractores",
      //   icon: "img:icons/detractor.svg",
      //   link: "/detractors"
      // },
      {
        title: "Comentarios",
        icon: "eva-email-outline",
        link: "/comments"
      }
    ],
    manager: [
      {
        title: "Dashboard",
        icon: "img:icons/dashboard-white.svg",
        link: "/"
      },
      {
        title: "Encuestas",
        icon: "img:icons/clipboard.svg",
        link: "/surveys"
      },
      // {
      //   title: "Detractores",
      //   icon: "img:icons/detractor.svg",
      //   link: "/detractors"
      // },
      {
        title: "Comentarios",
        icon: "eva-email-outline",
        link: "/comments"
      },
      {
        title: "Usuarios",
        icon: "img:icons/user.svg",
        link: "/users",
        type: 'config'
      },
      {
        title: "Sucursales",
        icon: "img:icons/map-pin.svg",
        link: "/branches",
        type: 'config'
      },
      {
        title: "Distribuidores",
        icon: "img:icons/shopping-bag.svg",
        link: "/dealers",
        type: 'config'
      }
    ],
    general_manager: [
      {
        title: "Dashboard",
        icon: "img:icons/dashboard-white.svg",
        link: "/"
      },
      {
        title: "Encuestas",
        icon: "img:icons/clipboard.svg",
        link: "/surveys"
      },
      // {
      //   title: "Detractores",
      //   icon: "img:icons/detractor.svg",
      //   link: "/detractors"
      // },
      {
        title: "Comentarios",
        icon: "eva-email-outline",
        link: "/comments"
      },
      {
        title: "Usuarios",
        icon: "img:icons/user.svg",
        link: "/users",
        type: 'config'
      },
      {
        title: "Sucursales",
        icon: "img:icons/map-pin.svg",
        link: "/branches",
        type: 'config'
      },
      {
        title: "Distribuidores",
        icon: "img:icons/shopping-bag.svg",
        link: "/dealers",
        type: 'config'
      }
    ]
  }
}
