import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/question-templates',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await axiosInstance({
      url: '/question-templates',
      data: data,
      method: 'POST'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, data) {
  try {
    const response = await axiosInstance({
      url: '/question-templates/' + data.id,
      data: data,
      method: 'PUT'
    })
    return response
  } catch (e) {
    return e
  }
}
