import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/customers',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await axiosInstance({
      url: '/customers',
      data: data,
      method: 'POST'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function remove(ctx, position) {
  ctx.commit('remove', position)
}

export async function items(ctx, items) {
  ctx.commit('items', items)
}

export function onSaveError(ctx, position){
  ctx.commit('saveError', position)
}

export function onSaveSuccess(ctx, position){
  ctx.commit('saveSuccess', position)
}
