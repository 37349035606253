import CurrencyFilter from 'src/filters/currency'
import DateFilter from 'src/filters/date'
import PhoneFilter from 'src/filters/phone'
import TypeEmployeeFilter from 'src/filters/typeEmployee'
import TypeCustomerFilter from 'src/filters/typeCustomer'
import TypeProductFilter from 'src/filters/typeProduct'
import dateRelative from 'src/filters/dateRelative'

export default async ({ Vue }) => {
  Vue.filter('currency', CurrencyFilter)
  Vue.filter('date', DateFilter)
  Vue.filter('phone', PhoneFilter)
  Vue.filter('typeEmployee', TypeEmployeeFilter)
  Vue.filter('typeCustomer', TypeCustomerFilter)
  Vue.filter('typeProduct', TypeProductFilter)
  Vue.filter('dateRelative', dateRelative)
}
