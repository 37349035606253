import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/branches',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function eliminate(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: `/branches/${queryParams.id}`,
      method: 'DELETE'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await axiosInstance({
      url: '/branches',
      data: data,
      method: 'POST'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, queryParams) {
  try {
    const response = await axiosInstance({
      url: `/branches/${queryParams.id}`,
      data: queryParams.data,
      method: 'PUT'
    })
    return response
  } catch (e) {
    return e
  }
}
